const navMobile = document.querySelector('.nav-mobile')
const navBtn = document.querySelector('.hamburger')
const navLinks = document.querySelectorAll('.nav__link')
const itGeeksLogo = document.querySelector('.logo')

document.addEventListener('DOMContentLoaded', () => {
	navBtn.addEventListener('click', () => {
		navBtn.classList.toggle('is-active')
		navMobile.classList.toggle('nav-mobile--active')
	})
})

navLinks.forEach(item => {
    item.addEventListener('click', (event) => {
        // Jeśli element ma klasę 'translate-box', zatrzymaj domyślne zachowanie
        if (item.classList.contains('translate-box')) {
            event.preventDefault();
        } else {
            // W przeciwnym razie zamknij nawigację
            navMobile.classList.remove('nav-mobile--active');
            navBtn.classList.remove('is-active');
        }
    });
});

itGeeksLogo.addEventListener('click', () => {
	navBtn.classList.remove('is-active')
	navMobile.classList.remove('nav-mobile--active')
})

document.addEventListener('DOMContentLoaded', function () {
	const nav = document.querySelector('.mobile-underline')
	const scrollDistance = 150

	function handleScroll() {
		if (window.scrollY > scrollDistance) {
			nav.classList.add('nav-scrolled')
		} else {
			nav.classList.remove('nav-scrolled')
		}
	}

	window.addEventListener('scroll', handleScroll)

	handleScroll()
})

// GOOGLE TRANSLATE (przenoszenie tłumaczenia)

function moveTranslateBox() {
    const translateBox = document.querySelector('.translate-box'); // Pobiera element tłumaczenia
    const navDesktop = document.querySelector('.nav-desktop');    // Nawigacja desktopowa
    const navMobile = document.querySelector('.nav-mobile');      // Nawigacja mobilna

    if (translateBox) {
        if (window.innerWidth > 768) {
            // Przenoszenie do nawigacji desktopowej
            if (!navDesktop.contains(translateBox)) {
                navDesktop.appendChild(translateBox);
            }
        } else {
            // Przenoszenie do nawigacji mobilnej
            if (!navMobile.contains(translateBox)) {
                navMobile.appendChild(translateBox);
            }
        }
    }
}

// Upewniamy się, że skrypt będzie działał po załadowaniu strony
document.addEventListener('DOMContentLoaded', () => {
    moveTranslateBox();
});

// Nasłuchiwanie na zmianę rozmiaru okna
window.addEventListener('resize', moveTranslateBox);


// ACORDEON

document.addEventListener('DOMContentLoaded', function() {
    const accordionItems = document.querySelectorAll('.subpage-accordion-item');

    accordionItems.forEach(item => {
        const header = item.querySelector('.subpage-accordion-header');
        header.addEventListener('click', () => {
            const isActive = item.classList.contains('active');

            // Zamyka wszystkie inne sekcje
            accordionItems.forEach(otherItem => {
                otherItem.classList.remove('active');
            });

            // Przełącza aktywność tylko dla klikniętej sekcji
            if (!isActive) {
                item.classList.add('active');
            }
        });
    });
});

// USUNIĘCIE LINKU Z TŁUMACZENIA

// const interval = setInterval(function () {
// 	const element = document.querySelector('.eapps-widget .hQJLxj a')
// 	if (element) {
// 		element.style.display = 'none'
// 		clearInterval(interval) // Przestań sprawdzać, gdy element zostanie znaleziony
// 	}
// }, 1) // Sprawdza co 500 milisekund

//COOCKIE
const footerYear = document.querySelector('.footer__year')

document.addEventListener('DOMContentLoaded', function () {
	const cookieNotification = document.getElementById('cookie-notification')
	const acceptButton = document.getElementById('accept-cookie')

	const COOKIE_NAME = 'cookieConsent'
	const COOKIE_EXPIRATION_DAYS = 45

	function setCookie(name, value, days) {
		const d = new Date()
		d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000)
		const expires = 'expires=' + d.toUTCString()
		document.cookie = name + '=' + (value || '') + ';' + expires + ';path=/'
	}

	function getCookie(name) {
		const nameEQ = name + '='
		const ca = document.cookie.split(';')
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i]
			while (c.charAt(0) === ' ') c = c.substring(1)
			if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
		}
		return null
	}

	function checkCookieConsent() {
		const consent = getCookie(COOKIE_NAME)
		if (consent) {
			cookieNotification.classList.add('hidden')
		} else {
			cookieNotification.classList.remove('hidden')
		}
	}

	acceptButton.addEventListener('click', function () {
		setCookie(COOKIE_NAME, 'true', COOKIE_EXPIRATION_DAYS)
		cookieNotification.classList.add('hidden')
	})

	checkCookieConsent()
})

// FOOTER
const handleCurrentYear = () => {
	const year = new Date().getFullYear()
	footerYear.innerText = year
}

handleCurrentYear()